import './iconStyle.scss';
import PropTypes from 'prop-types';
import { ReactComponent as Phone } from '../../../src/assets/svgIcons/Phone.svg';
import { ReactComponent as Mail } from '../../../src/assets/svgIcons/Mail.svg';
import { ReactComponent as FacebookLogo } from '../../../src/assets/svgIcons/FacebookLogo.svg';
import { ReactComponent as LinkedinLogo } from '../../../src/assets/svgIcons/LinkedinLogo.svg';
import { ReactComponent as WhatsappLogo } from '../../../src/assets/svgIcons/WhatsappLogo.svg';
import { ReactComponent as Pin } from '../../../src/assets/svgIcons/Pin.svg';
import { ReactComponent as ShareNetwork } from '../../../src/assets/svgIcons/ShareNetwork.svg';
import { getContactInfo } from '../../heplers/contactsList';
import { Tooltip } from '@mui/material';

//need to rework whole component
const Icon = ({ id, text, footer, href, isDefault, isContacts, subLink, header }) => {
  const findedHref = getContactInfo(id);
  return (
    <div
      className={
        isContacts
          ? 'contactsTemplateText'
          : isDefault
          ? 'defaultWrapper'
          : text
          ? `liWrapper${footer ? '-footer' : ''}`
          : `liWrapper${footer ? '-footer' : ''} liWrapperNoText`
      }
    >
      <div
        className={
          isContacts
            ? 'contactsTemplate'
            : isDefault
            ? 'defaultIconWrapper'
            : text
            ? `iconWrapper textIncluded ${footer ? 'footer-hasText' : ''}`
            : `iconWrapper noText ${footer ? 'footer' : ''}`
        }
      >
        {id === 'phone' && <Phone className={`${header ? 'iconHeader' : 'icon'}`} />}
        {id === 'email' && <Mail className={`${header ? 'iconHeader' : 'icon'}`} />}
        <a href={findedHref.href}>
          {' '}
          {id === 'linkedin' && <LinkedinLogo className={`${header ? 'iconHeader' : 'icon'}`} />}
        </a>
        <a href={findedHref.href}>
          {id === 'facebook' && <FacebookLogo className={`${header ? 'iconHeader' : 'icon'}`} />}
        </a>
        {/* <a href={findedHref.href}>{id === 'whatsapp' && <WhatsappLogo className={'icon'} />}</a> */}
        {id === 'address' && <Pin className={'icon path'} />}
        {id === 'share' && <ShareNetwork className={`${header ? 'iconHeader' : 'icon'}`} />}
      </div>
      {subLink && (
        <Tooltip
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: '#22242C',
                borderRadius: '2px',
                fontSize: '14px',
                fontWeight: '500',
                lineHeight: '20px',
                padding: '12px 16px 12px 16px',
                '& .MuiTooltip-arrow': {
                  color: '#22242C',
                },
              },
            },
          }}
          title={'Click to call'}
          arrow
        >
          <button
            type="button"
            data-voice="b50add1f-a789-4911-bd12-d04034fee49b"
            style={{
              border: 'none',
              backgroundColor: 'white',
              borderRight: '1px solid #979CAF',
              padding: '0px 9px 0px 0px',
            }}
            className={
              isContacts
                ? 'contactsTemplateText'
                : isDefault
                ? 'defaultWrapper'
                : text
                ? `liWrapper${footer ? '-footer' : ''}`
                : `liWrapper${footer ? '-footer' : ''} liWrapperNoText`
            }
          >
            {subLink}
          </button>
        </Tooltip>
      )}
      <a href={findedHref.href !== '#' ? findedHref.href : undefined}>{text}</a>
    </div>
  );
};

export default Icon;

Icon.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  footer: PropTypes.bool,
  href: PropTypes.string,
  isDefault: PropTypes.bool,
  isContacts: PropTypes.bool,
};
