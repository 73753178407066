import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import Home from './pages/home2';
import Services from './pages/services';
import Contacts from './pages/contacts';
import Genesys from './pages/genesys';
import JobDescription from './pages/career/JobDescription';
import HireUs from './pages/hireUs';
import Page404 from './pages/page404';
import Blog from './pages/blog';
import CareerWithConsumer from './pages/career';
import { Monobot } from './pages/monobot';
import { PrivacyPolicy } from './pages/privacyPolicy';
import ScrollToTop from './heplers/scrollToTop';
import { useEffect, useRef } from 'react';
import { TermsAndConditions } from './pages/termsAndConditions';
function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <div>
          <main className="container">
            <Routes>
              <Route
                path="/"
                element={
                  <Layout>
                    <Home />
                  </Layout>
                }
              />
              <Route
                path="/hire"
                element={
                  <Layout>
                    <HireUs />
                  </Layout>
                }
              />
              <Route
                path="/services"
                element={<Navigate to={'/software-development-services'} />}
              />
              <Route
                path="/genesys"
                element={<Navigate to={'/genesys-contact-center-solutions'} />}
              />
              <Route
                path="/software-development-services"
                element={
                  <Layout>
                    <Services />
                  </Layout>
                }
              />
              <Route
                path="/career"
                element={
                  <Layout>
                    <CareerWithConsumer />
                  </Layout>
                }
              />
              <Route
                path="/genesys-contact-center-solutions"
                element={
                  <Layout>
                    <Genesys />
                  </Layout>
                }
              />
              <Route
                path="/contacts"
                element={
                  <Layout>
                    <Contacts />
                  </Layout>
                }
              />
              <Route
                path="/monobot"
                element={
                  <Layout>
                    <Monobot />
                  </Layout>
                }
              />
              <Route
                path="/blog"
                element={
                  <Layout>
                    <Blog />
                  </Layout>
                }
              >
                <Route
                  path=":vacancyId"
                  element={
                    <Layout>
                      <JobDescription />
                    </Layout>
                  }
                />
              </Route>
              <Route
                path="/privacy-policy"
                element={
                  <Layout>
                    <PrivacyPolicy />
                  </Layout>
                }
              />
              <Route
                path="/terms-and-conditions"
                element={
                  <Layout>
                    <TermsAndConditions />
                  </Layout>
                }
              />
              <Route path="*" element={<Page404 />} />
            </Routes>
          </main>
        </div>
      </ScrollToTop>
    </BrowserRouter>
  );
}

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default App;
