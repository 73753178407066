import PropTypes from 'prop-types';
import './smallitem.scss';
import { useNavigate } from 'react-router-dom';
export const SmallBlogItem = ({ item }) => {
  const { ID, DATE, Title, URL, SEO, Image, AltText, Category, Tags, Content } = item;
  const navigate = useNavigate();
  return (
    <div
      // onClick={() => {
      //   navigate(`/blog${URL}`, { state: URL });
      // }}
      className="smallitemWrapper"
      key={ID}
    >
      <img
        id={`${Title}smallBlogItem`}
        className="smallitemWrapper-image"
        alt={AltText}
        src={`/blogImages/smallBlogImages/${Image}.jpg`}
      ></img>
      <div className="smallitemWrapper-content">
        <a href={`/blog${item.URL}`} className="smallitemWrapper-title">
          {Title}
        </a>
        <span className="smallitemWrapper-date">{DATE}</span>
      </div>
    </div>
  );
};

SmallBlogItem.props = {
  item: PropTypes.object,
  key: PropTypes.number,
};
